
          @import "~@/assets/css/_variables";
          @import "~@/assets/css/_sizes";
          @import "~@/assets/css/_sizesMobile";
        


















































































































































































































.faq-logo {
  border: none;
  transition: all 0.3s ease;
  .logo {
    width: 180px;
    height: 40px;
    left: 5vw;
    top: 2vw;
    transform: none;
    bottom: auto;
    @media screen and (min-width: 768px) {
      left: 4vw;
    }
  }
}
.page-FAQ {
  .content {
    overflow: hidden;
    height: 26vh;
    display: flex;
    align-items: flex-end;
    @media screen and (min-width: 768px) {
      height: 16vh;
      position: relative;
      top: 17px;
    }
    h2 {
      font-size: 6vh;
      @media screen and (min-width: 768px) {
        font-size: 4vh;
      }
    }
  }
}
.faq-wraper {
  background: #f9f9f9;
  position: relative;
  padding: 1vh 0 2vh;
  height: calc(100vh - 26vh - 15vw);
  box-sizing: border-box;
  @media screen and (min-width: 768px) {
    padding: 4vh 0 2vh;
    height: calc(100vh - 16vh - 4.58333333vw);
  }
}
.faq-articles {
  box-sizing: border-box;
  position: relative;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: auto;
  justify-self: stretch;
  align-items: stretch;
  gap: 0;
  height: 100%;
  padding: 0 2vh;
  overflow-y: auto;
  @media screen and (min-width: 768px) {
    gap: 4vh;
    justify-self: auto;
    align-items: auto;
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media screen and (min-width: 1200px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media screen and (min-width: 1600px) {
    grid-template-columns: repeat(5, 1fr);
  }
}
.faq-category-title {
  display: inline-block;
  color: #6d7275;
  font-size: 2.4vh;
  margin-bottom: 1.6vh;
  @media screen and (min-width: 768px) {
    font-size: 1.4vh;
  }
  @media screen and (min-width: 2200px) {
    font-size: 1.2vw;
  }
}
.faq-article-link {
  display: block;
  padding: 1vh 1vh 1vh 3vh;
  text-decoration: none;
  font-size: 14px;
  @media screen and (min-width: 768px) {
    font-size: 16px;
    padding: 0.4vh 1vh 0.4vh 2vh;
  }
  @media screen and (min-width: 2200px) {
    font-size: 22px;
    padding: 8px 8px 8px 36px;
  }
  &:hover {
    text-decoration: underline;
  }
  &:before {
    content: '';
    width: 10px;
    height: 2px;
    border-radius: 2px;
    position: absolute;
    background: #1eae8d;
    margin-top: 7px;
    margin-left: -2.6vh;
    @media screen and (min-width: 768px) {
      margin-top: 8px;
      width: 12px;
      margin-left: -1.6vh;
    }
    @media screen and (min-width: 2200px) {
      width: 20px;
      height: 4px;
      margin-top: 12px;
      margin-left: -36px;
    }
  }
}
.faq-overlay-wraper {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9;
  background: rgba(38, 38, 38, 0.8);
}
.faq-overlay-content {
  position: relative;
  max-width: 800px;
  max-height: 80vh;
  margin: 20vh auto 0;
  height: 100vh - 20vh;
  border-radius: 2vh 2vh 0 0;
  background: #fff;
  padding: 4vh;
  font-size: 2.4vh;
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 768px) {
    font-size: 1.4vh;
  }
}
.faq-overlay-close {
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(0.125turn);
  position: absolute;
  top: 2vh;
  right: 2vh;
  width: 40px;
  height: 40px;
  border-radius: 5vh;
  background: #e6e6ea;
  z-index: 9;
  @media screen and (min-width: 768px) {
    top: 3vh;
    right: 3vh;
  }
  &:before {
    content: '';
    position: absolute;
    width: 20px;
    height: 2px;
    background: #000;
  }
  &:after {
    content: '';
    position: absolute;
    width: 2px;
    height: 20px;
    background: #000;
  }
}
.faq-article-content {
  box-sizing: border-box;
  max-height: 100%;
  overflow-y: auto;
}
.faq-article-title {
  position: relative;
  font-weight: bold;
  font-size: 3vh;
  padding-right: 40px;
  @media screen and (min-width: 768px) {
  }
}
.faq-spoiler {
  position: relative;
  & + & {
    border-top: 1px solid #d6d9de;
  }
}
.faq-spoiler-title {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #393939;
  height: 10vh;
  font-size: 2.2vh;
}
.faq-spoiler-title::after {
  content: '';
  position: absolute;
  background-image: url(../assets/images/keyboard_arrow_down-24px.svg);
  background-size: 3.5vh 3.5vh;
  background-position: center center;
  background-repeat: no-repeat;
  width: 3vh;
  height: 3vh;
  right: 1.2vh;
  top: calc(50% - 1.5vh);
  transition: 100ms;
}
.faq-spoiler-title.opened::after {
  transform: rotate(180deg);
}
.faq-spoiler-content {
  padding-bottom: 3vh;
}
